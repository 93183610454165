define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/receiptPrinterSetting',

  'modules/admin/behaviors/loader',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.common/components/mode',

  'modules/profile/models/profile',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
], (
  $, _, Backbone, Template,
  Loader, Locale,
  Printing, ModeComponent,
  ProfileModel, ReceiptPrinterModel,
) => Backbone.Marionette.LayoutView.extend({

  TYPE_USB: 'usb',
  TYPE_NETWORK: 'network',

  template: Template,

  events: {
    'click @ui.deviceTypeUsbButton': 'switchDeviceTypeToUsb',
    'click @ui.deviceTypeNetworkButton': 'switchDeviceTypeToNetwork',
    'click @ui.saveButton': 'saveSelectedReceiptPrinter',
    'keyup @ui.networkIp': 'textInputUI',
    'change @ui.networkIp': 'textChangeUI',
    'change @ui.usbPrinterSelect': 'selectUsbPrinterChangedUI',
  },

  ui: {
    usbPrinter: '[data-ui="usb-receipt-printer"]',
    usbPrinterSelect: '[data-ui="select"]',
    networkPrinter: '[data-ui="network-receipt-printer"]',
    saveButton: '[data-ui="save-button"]',
    networkIp: '[data-ui="network-ip"]',
    networkStatusCircle: '[data-ui="network-status-circle"]',
    deviceTypeNetworkButton: '[data-ui="device-type-network"]',
    deviceTypeUsbButton: '[data-ui="device-type-usb"]'
  },

  initialize({
    printers,
    showLabel,
    updateInModel,
    setFirstOptionInModel,
    targetPathId,
    targetPathType,
    availableTypes,
    printerModel,
  }) {
    this.printers = printers || [];
    this.showLabel = showLabel;
    this.updateInModel = updateInModel;
    this.setFirstOptionInModel = setFirstOptionInModel;
    this.targetPathId = targetPathId;
    this.targetPathType = targetPathType;
    this.printerModel = printerModel || ReceiptPrinterModel;

    this.types = availableTypes || [
      this.TYPE_USB,
      this.TYPE_NETWORK,
    ];
    this.typeIndex = 0;

    this.originalValue = this.printerModel.get('id');

    this.initializeDataModel();

    const type = this.printerModel.get('type');
    if (
      !this.printerInList(this.originalValue)
      && this.printers.length > 0
      && type === ReceiptPrinterModel.PRINTER_TYPE_ESCPOS
    ) {
      // Save usb printer where there is one and none has been selected yet.
      const id = this.printers[0].id;

      if (this.updateInModel) {
        this.setOnModel(id, type);
      } else {
        this.saveReceiptPrinter(
          id,
          ReceiptPrinterModel.PRINTER_TYPE_ESCPOS,
        );
      }
      this.dataModel.set('usbPrinter', id);
    }

    if (type === ReceiptPrinterModel.PRINTER_TYPE_ESCPOS_NETWORK) {
      this.switchDeviceTypeToNetwork();
    } if (type === ReceiptPrinterModel.PRINTER_TYPE_ESCPOS) {
      this.switchDeviceTypeToUsb();
    }
  },

  initializeDataModel() {
    const id = this.printerModel.get('id');
    const type = this.printerModel.get('type');

    let usbPrinterValue;
    if (type === ReceiptPrinterModel.PRINTER_TYPE_ESCPOS && id && this.printerInList(id)) {
      usbPrinterValue = id;
    } else if (this.printers.length > 0) {
      usbPrinterValue = this.printers[0].id;
    } else {
      usbPrinterValue = null;
    }

    let networkPrinterValue;
    if (type === ReceiptPrinterModel.PRINTER_TYPE_ESCPOS_NETWORK && id) {
      networkPrinterValue = id;
    } else {
      networkPrinterValue = null;
    }

    this.dataModel = new Backbone.Model({
      usbPrinter: usbPrinterValue,
      networkPrinter: networkPrinterValue,
    });
  },

  serializeData() {
    return {
      printers: this.printers,
      hasPrinters: this.printers && this.printers.length > 0,
      showLabel: this.showLabel,
    };
  },

  printerInList(id) {
    return this.printers.find((printer) => printer.id === id);
  },

  textInputUI() {
    this.dataModel.set('networkPrinter', this.ui.networkIp.val());
  },

  textChangeUI() {
    const ipAddress = this.dataModel.get('networkPrinter');
    const ipValid = this.isValidIpAddress(ipAddress);

    if (this.updateInModel) {
      if (ipValid) {
        this.setOnModel(ipAddress, ReceiptPrinterModel.PRINTER_TYPE_ESCPOS_NETWORK);
      } else {
        this.setOnModel(null, null);
      }
    }
  },

  selectUsbPrinterChangedUI() {
    this.dataModel.set('usbPrinter', this.ui.usbPrinterSelect.val());
  },

  updateUsbPrinter() {
    if (this.updateInModel && this.printers.length > 0) {
      this.setOnModel(this.dataModel.get('usbPrinter'), ReceiptPrinterModel.PRINTER_TYPE_ESCPOS);
    }

    this.render();
  },

  updateNetworkPrinter() {
    const ipAddress = this.dataModel.get('networkPrinter');
    const ipValid = this.isValidIpAddress(ipAddress);

    if (
      (ipValid && this.originalValue !== ipAddress || ipAddress === null)
      && !this.updateInModel
    ) {
      this.ui.saveButton.show();
    } else {
      this.ui.saveButton.hide();
    }

    this.updateNetworkCircle();
  },

  onRender() {
    const usbPrinter = this.dataModel.get('usbPrinter');
    const ipAddress = this.dataModel.get('networkPrinter');

    this.ui.usbPrinterSelect.val(usbPrinter);
    this.ui.networkIp.val(ipAddress);

    this.updateIpAddressText();
    this.updateNetworkCircle();

    if (this.updateInModel) {
      // If auto set is enabled, you should not be able to save the setting manually
      this.ui.saveButton.hide();
    }

    const type = this.getSelectedDeviceType();

    if (type === this.TYPE_USB) {
      this.ui.networkPrinter.hide();

      if (this.originalValue === usbPrinter || usbPrinter === null) {
        this.ui.saveButton.hide();
      }
    } else if (type === this.TYPE_NETWORK) {
      this.ui.usbPrinter.hide();

      if (
        !this.isValidIpAddress(ipAddress)
        || this.originalValue === ipAddress
      ) {
        // You should not be able to save with an invalid ip
        this.ui.saveButton.hide();
      }
    }

    // Hide the unavailable type's buttons
    if (!this.isAvailableDeviceType(this.TYPE_NETWORK)) {
      this.ui.deviceTypeNetworkButton.hide();
    }

    if (!this.isAvailableDeviceType(this.TYPE_USB)) {
      this.ui.deviceTypeUsbButton.hide();
    }
  },

  onShow() {
    this.model.on(`change:${this.targetPathId}`, this.updateIpAddressText, this);
    this.dataModel.on('change:usbPrinter', this.updateUsbPrinter, this);
    this.dataModel.on('change:networkPrinter', this.updateNetworkPrinter, this);

    this.updateIpAddressText();

    const usbPrinter = this.dataModel.get('usbPrinter');
    if (this.getSelectedDeviceType() === this.TYPE_USB && this.setFirstOptionInModel && usbPrinter) {
      this.setOnModel(usbPrinter, ReceiptPrinterModel.PRINTER_TYPE_ESCPOS);
    }
  },

  onDestroy() {
    this.model.off(`change:${this.targetPathId}`, this.updateIpAddressText, this);
    this.dataModel.off('change:usbPrinter', this.updateUsbPrinter, this);
    this.dataModel.off('change:networkPrinter', this.updateNetworkPrinter, this);
  },

  setOnModel(id, type) {
    this.model.set({
      [this.targetPathId]: id,
      [this.targetPathType]: type,
    });

    this.triggerMethod('setting:updated', this.targetPathId);
  },

  saveReceiptPrinter(id, type) {
    this.setOnModel(id, type);

    if (id) {
      ReceiptPrinterModel.setPrinter(id, type);
    } else {
      ReceiptPrinterModel.resetPrinter();
    }

    this.originalValue = id;

    ReceiptPrinterModel.reload();
    this.render();
  },

  saveSelectedReceiptPrinter() {
    const usbPrinter = this.dataModel.get('usbPrinter');
    const ipAddress = this.dataModel.get('networkPrinter');
    const type = this.getSelectedDeviceType();

    if (type === this.TYPE_NETWORK) {
      this.saveReceiptPrinter(ipAddress, ReceiptPrinterModel.PRINTER_TYPE_ESCPOS_NETWORK);
    } else if (type === this.TYPE_USB) {
      this.saveReceiptPrinter(usbPrinter, ReceiptPrinterModel.PRINTER_TYPE_ESCPOS);
    }
  },

  switchToDeviceType(type) {
    const index = this.types.findIndex((t) => type === t);

    if (index > -1) {
      this.typeIndex = index;
      this.render();
    } else {
      console.warn(`Could not switch to device type ${type}`);
    }
  },

  updateIpAddressText() {
    if (
      this.getSelectedDeviceType() === this.TYPE_NETWORK
      && this.printerModel.get('type') === ReceiptPrinterModel.PRINTER_TYPE_ESCPOS_NETWORK
    ) {
      const printerId = this.model.get(this.targetPathId);

      if (printerId) {
        this.ui.networkIp.val(printerId);
      } else {
        this.ui.networkIp.val('');
      }

      this.updateNetworkCircle();
    }
  },

  updateNetworkCircle() {
    const ipAddress = this.dataModel.get('networkPrinter');

    if (!ipAddress) {
      this.ui.networkStatusCircle
        .removeClass('fa-check-circle')
        .removeClass('fa-times-circle')
        .addClass('fa-minus-circle')

        .removeClass('text-success')
        .removeClass('text-danger')
        .addClass('text-warning');
    } else if (!this.isValidIpAddress(ipAddress)) {
      this.ui.networkStatusCircle
        .removeClass('fa-check-circle')
        .removeClass('fa-minus-circle')
        .addClass('fa-times-circle')

        .removeClass('text-success')
        .removeClass('text-warning')
        .addClass('text-danger');
    } else {
      this.ui.networkStatusCircle
        .removeClass('fa-times-circle')
        .removeClass('fa-minus-circle')
        .addClass('fa-check-circle')

        .removeClass('text-danger')
        .removeClass('text-warning')
        .addClass('text-success');
    }
  },

  getSelectedDeviceType() {
    return this.types[this.typeIndex];
  },

  isAvailableDeviceType(type) {
    return this.types.includes(type);
  },

  switchDeviceTypeToUsb() {
    this.switchToDeviceType(this.TYPE_USB);

    const usbPrinter = this.dataModel.get('usbPrinter');
    if (this.updateInModel && usbPrinter) {
      this.setOnModel(usbPrinter, ReceiptPrinterModel.PRINTER_TYPE_ESCPOS);
    }
  },

  switchDeviceTypeToNetwork() {
    this.switchToDeviceType(this.TYPE_NETWORK);

    const ipAddress = this.dataModel.get('networkPrinter');
    if (this.updateInModel && ipAddress) {
      this.setOnModel(ipAddress, ReceiptPrinterModel.PRINTER_TYPE_ESCPOS_NETWORK);
    }
  },

  isValidIpAddress(ipAddress) {
    // From: https://stackoverflow.com/a/26445549
    return /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ipAddress);
  },

}));
