define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/posSettings/technician/welcomePopup.hbs',

], (
  $, _, Backbone, PopupView, Template,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--simple',

  ui: {
    close: '[data-action="close"]',
    accept: '[data-ui="accept"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'click @ui.accept': 'confirmClicked',
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    this.logOpenAction('modules/shop.cash-register-retail/views/posSettings/technician/welcomePopup');

    this.openPopup();
    return def;
  },

}));
