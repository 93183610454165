define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/productListWithSearch/list/resultCollectionItem',
  'modules/shop.cash-register-retail/components/productStock',
], (
  $, _, Backbone, Template, ProductStock,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  events: {
    // We won't listen to touchend, because this breaks scrolling
    click: 'rowClicked',
  },

  rowClicked() {
    this.triggerMethod('product:selected', this.model);
  },

  serializeData() {
    return _.extend({}, this.model.toJSON(), {
      stockAmount: ProductStock.getStockFromFlatProduct(this.model),
      stockClass: ProductStock.getStockTextClassFromFlatProduct(this.model),
    });
  },

}));
