define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cellWithKeyboard',
  'modules/shop.cash-register-retail/templates/products/list/cellPpu',
], (
  $, _, Backbone, CellView, Template,
) => CellView.extend({

  template: Template,

  initialize(options) {
    options = options || {};

    this.productModel = options.productModel;
    this.keyboardMode = this.keyboardModel.MODE_PRODUCT_PPU;

    CellView.prototype.initialize.call(this, options);
  },

  onModeSelected() {
    this.previousPpu = this.productModel.get('ppu_wt');
    CellView.prototype.onModeSelected.call(this);
  },

  onModeDeselected() {
    if (this.descriptionChanged()) {
      this.confirmPrice();
    } else {
      this.resetPrice();
    }
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      let currentPrice = this.productModel.get('ppu_wt');
      if (this.firstInput) {
        currentPrice = '0.00';
        this.firstInput = false;
      }
      let newPrice = this.handleInputOnPrice(currentPrice, key);
      if (parseFloat(newPrice) > 100000.00) {
        newPrice = '100000.00';
      }

      this.productModel.set({
        ppu_wt: newPrice,
      });
    }
  },

  onBackspacePress() {
    // Mark price as changed
    this.firstInput = false;
    // Update the price
    const newPrice = this.removeDigitFromPrice(this.productModel.get('ppu_wt'));
    this.productModel.set('ppu_wt', newPrice);
  },

  onRender() {
    this.$el.attr('data-ppu-wt', this.productModel.get('id'));
  },

  onShow() {
    this.productModel.on('change:ppu_wt', this.render, this);
  },

  onDestroy() {
    this.productModel.off('change:ppu_wt', this.render, this);
  },

  onConfirmation() {
    CellView.prototype.onConfirmation.call(this);
    this.confirmPrice();
    this.productModel.setPpuWt(
      this.productModel.get('ppu_wt'),
    );
    this.productModel.clearLengthPriceIfSet();
    this.productModel.calculateTotalPrices();
  },

  descriptionChanged() {
    const currentPrice = this.productModel.get('ppu_wt');
    const previousPrice = this.previousPpu;
    if (currentPrice !== '0.00') {
      return false;
    }
    return currentPrice !== previousPrice;
  },

  confirmPrice() {
    this.productModel.set({
      discount_ppu_wt: this.productModel.getDiscountPpuWt(),
      before_discount_ppu_wt: this.productModel.get('ppu_wt'),
    });
    this.previousPpu = this.productModel.get('ppu_wt');
  },

  resetPrice() {
    this.productModel.set({
      ppu_wt: this.previousPpu,
    });
  },

  onCancel() {
    this.resetPrice();
  },

  serializeData() {
    const data = this.productModel.toJSON();
    data.ppu_wt_formatted = this.productModel.formatPrice(data.ppu_wt || '0.00');
    return data;
  },

}));
