define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/list/layout',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/views/products/list/row',
  'modules/shop.cash-register-retail/views/products/list/rowLoader',

  'modules/common/components/locale',
], (
  $, _, Backbone, Template,
  CurrentOrderItemCollection, RowView, RowLoaderView,
  Locale,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childView: RowView,

  getRowView() {
    return RowView;
  },

  getRowLoaderView() {
    return RowLoaderView;
  },

  getChildView(model) {
    switch (model.get('type')) {
      case CurrentOrderItemCollection.TYPE_LOADER:
        return this.getRowLoaderView();
      default:
        return this.getRowView();
    }
  },

  childViewOptions() {
    return {
      itemPriceWithName: this.itemPriceWithName,
      columns: this.columns,
      editable_columns: this.editable_columns,
      showUnitPriceBelowDescription: this.showUnitPriceBelowDescription,
      onKeyPadOpen: this.onKeyPadOpen,
      loadQuantityOnInitialize: this.loadQuantityOnInitialize,
      showIcp: this.showIcp,
    };
  },

  childViewContainer: 'tbody',

  className: 'products-list',

  attributes: {
    id: 'products-list',
  },

  childEvents: {
    'product:clicked': 'productClicked',
    'product:total': 'productTotal',
    'product:quantity': 'productQuantity',
    'product:needs-weight': 'needsWeight',
    'product:needs-description': 'needsDescription',
  },

  needsWeight(view, productModel) {
    this.triggerMethod('product:needs-weight', productModel);
  },

  needsDescription(view, productModel) {
    this.triggerMethod('product:needs-description', productModel);
  },

  productClicked(view, productModel) {
    this.triggerMethod('product:clicked', productModel);
  },

  productQuantity(view, data) {
    this.triggerMethod('product:quantity', data);
  },

  productTotal(view, data) {
    this.triggerMethod('product:total', data);
  },

  scrollToUpdatedProduct(model) {
    if (model) {
      model.trigger('change:quantity', true);
    }
  },

  onShow() {
    // the initial list is rendered so all new items should trigger the quantity
    this.loadQuantityOnInitialize = false;
  },

  viewComparator(a, b) {
    const t1 = a.get('time');
    const t2 = b.get('time');
    if (t1 < t2) {
      return 1;
    } if (t1 > t2) {
      return -1;
    }
    return 0;
  },

  initialize(options) {
    this.collection = options.collection || CurrentOrderItemCollection;
    this.columns = options.columns;
    this.editable_columns = options.editable_columns;
    this.hideHeaders = options.hideHeaders || false;
    this.showUnitPriceBelowDescription = options.showUnitPriceBelowDescription || false;
    this.itemPriceWithName = options.itemPriceWithName || false;
    this.onKeyPadOpen = options.onKeyPadOpen;
    // @var used in swappables (description) so it does not trigger all list green
    // it should be used with combination with scrollToUpdatedProduct(model)
    // so product is beeing scrolled to
    this.loadQuantityOnInitialize = true;
    this.showIcp = !!options.showIcp;

    if (!this.columns) {
      this.columns = [
        'quantity',
        'description',
        'stock',
        'ppu',
        'discount',
        'price',
        'delete',
      ];
    }

    if (!this.editable_columns) {
      this.editable_columns = [
        'quantity',
        'description',
        'ppu',
        'discount',
        'price',
      ];
    }
  },

  getHeaders() {
    const headers = [];

    if (this.hideHeaders) {
      return headers;
    }

    this.columns.forEach((id) => {
      const addHeader = (name, cls = '') => headers.push({ id, name, cls });
      switch (id) {
        case 'quantity':
          addHeader(Locale.translate('quantity'), 'text-center');
          break;
        case 'description':
          addHeader(Locale.translate('description'));
          break;
        case 'deliverQuantity':
          addHeader(Locale.translate('delivered_quantity'));
          break;
        case 'stock':
          addHeader(); // Add empty header
          break;
        case 'ppu':
          addHeader(Locale.translate('price_per'), 'text-right');
          break;
        case 'discount':
          addHeader(Locale.translate('discount'), 'text-center');
          break;
        case 'price':
        case 'addonTotal':
          addHeader(Locale.translate('total'), 'text-right price-width');
          break;
        case 'delete':
          addHeader('', 'text-center');
          break;
        case 'return_quantity':
          addHeader(Locale.translate('return_quantity'), 'text-center');
          break;
      }
    });

    return headers;
  },

  serializeData() {
    return {
      headers: this.getHeaders(),
    };
  },

}));
