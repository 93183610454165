define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/description/measurements.hbs',

  'modules/common/components/currency',
  'modules/common/components/locale',
  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/views/products/description/length',
  'modules/shop.cash-register-retail/views/products/description/price',
  'modules/shop.cash-register-retail/components/scale',
], (
  $, _, Backbone, Template,
  Currency, Locale, Loader,
  LengthView, PriceView, ScaleComponent,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  ui: {
    totalPrice: '[data-ui="total-price"]',
  },

  regions: {
    length: '[data-region="length"]',
    price: '[data-region="price"]',
  },

  initialize({ hideMeasurements = false }) {
    this.hideMeasurements = hideMeasurements;
    this.previousLength = this.model.get('length');
    this.previousLengthPpuWt = this.model.get('length_ppu_wt');
    if (parseFloat(this.previousLengthPpuWt) <= 0) {
      this.model.setLengthPpuWt(this.model.get('ppu_wt'));
      this.previousLengthPpuWt = this.model.get('length_ppu_wt');
    }
  },

  onRender() {
    this.renderView('price', PriceView);
    this.lengthView = this.renderView('length', LengthView);
  },

  renderView(regionName, ViewCls) {
    const region = this.getRegion(regionName);
    const view = new ViewCls({
      model: this.model,
    });
    region.show(view);
    return view;
  },

  saveClicked() {
    this.setTotalPrice();
    this.setDescription();

    this.previousLength = this.model.get('length');
    this.previousLengthPpuWt = this.model.get('length_ppu_wt');
  },

  onShow() {
    this.renderTotalPrice();
    this.model.on('change:length', this.renderTotalPrice, this);
    this.model.on('change:length_ppu_wt', this.renderTotalPrice, this);

    if (this.needsWeightOnKassa()) {
      this.lengthView.$el.attr('disabled', true);
      this.updateWeightInterval = setInterval(() => {
        ScaleComponent.get().then((data) => {
          if (!this.isDestroyed) {
            this.lengthView.model.set('length', data.weight_kg);
          }
        });
      }, 350);
    }
  },

  needsWeightOnKassa() {
    return this.model.get('featured_attributes.needs_weight_on_kassa.value') === '1';
  },

  onDestroy() {
    this.model.off('change:length', this.renderTotalPrice, this);
    this.model.off('change:length_ppu_wt', this.renderTotalPrice, this);
    this.model.setLength(this.previousLength);
    this.model.setLengthPpuWt(this.previousLengthPpuWt);
    clearInterval(this.updateWeightInterval);
  },

  renderTotalPrice() {
    const ppuWt = this.model.get('length_ppu_wt');
    const length = this.model.get('length');
    const priceWt = Currency.Math.mul(ppuWt, length);
    this.ui.totalPrice.text(this.model.formatPrice(priceWt || '0.00'));
  },

  setTotalPrice() {
    const length = this.model.get('length');
    const ppuWt = this.model.get('length_ppu_wt');
    const quantity = parseInt(this.model.get('quantity'), 10).toFixed(2);
    if (
      length !== this.previousLength
            || ppuWt !== this.previousLengthPpuWt
    ) {
      const productPpu = Currency.Math.mul(ppuWt, length);
      const productPrice = Currency.Math.mul(productPpu, quantity);

      if (parseFloat(productPpu) > 0) {
        this.model.set({ price_wt: productPrice });
        this.model.setPpuWt(productPpu);
      } else {
        this.model.setPpuWt(this.model.get('before_discount_ppu_wt'));
      }
    }
  },

  setDescription() {
    const length = this.model.get('length');
    const ppuWt = this.model.get('length_ppu_wt');
    const orderDescription = this.model.get('order_description');
    let description = '';

    if (orderDescription.trim().length > 0) {
      description += orderDescription.trim();
    }

    if (parseFloat(length) > 0 && parseFloat(ppuWt) > 0) {
      if (description.length > 0) {
        description += '\n';
      }
      const maybe_kg = this.needsWeightOnKassa() ? ' kg' : '';
      description += `${length + maybe_kg} X ${ppuWt} = ${this.model.get('ppu_wt')}`;
    }

    this.model.setDescription(description);
  },

  serializeData() {
    const data = this.model.toJSON();
    data.needs_weight = this.needsWeightOnKassa();

    return data;
  },

}));
