define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './layout',
  'modules/shop.cash-register-retail/views/error',

  'modules/shop.cash-register-retail/components/restaurantCache',
], (
  $, _, Backbone, SwappableView,
  CollectionView, ErrorView,
  RestaurantCache,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(CollectionView, options);
    this.setView('error', ErrorView);
  },

  loadDataForView(name, childOptions, dataStorage) {
    const def = new $.Deferred();

    if (!name) {
      RestaurantCache.load()
        .then(def.resolve, (resp) => {
          def.reject();
          this.swapView('error', {
            error: resp.error,
          });
        });
    } else {
      def.resolve();
    }

    return def;
  },

}));
