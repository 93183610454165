define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/configureProductionGroups/layout.hbs',

  'modules/shop.cash-register-retail/collections/loadable/productionGroups',

  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/components/productionGroupPrint',

  'modules/common/components/locale',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/components/toaster',

  './collection',
], (
  $, _, Backbone, Template,
  ProductionGroups,
  ReceiptPrinter, ProductionGroupPrint,
  Locale, DeviceConfig, Toaster,
  ProductionGroupCollectionView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  regions: {
    productionGroups: '[data-region="production-groups"]',
  },

  initialize({ receiptPrinters }) {
    this.receiptPrinters = receiptPrinters;
    this.productionGroupPrinters = ProductionGroupPrint.getProductionGroupPrinters();
  },

  renderProductionGroupCollection() {
    const region = this.getRegion('productionGroups');
    const collectionView = new ProductionGroupCollectionView({
      collection: ProductionGroups,
      printers: this.receiptPrinters,
      productionGroupPrinters: this.productionGroupPrinters,
    });

    collectionView.on('printer:update', (productionGroupId, printerModel) => {
      if (printerModel.get('id') && printerModel.get('type')) {
        this.productionGroupPrinters[productionGroupId] = {
          id: printerModel.get('id'),
          type: printerModel.get('type'),
        };

        this.saveProductionGroupPrinters();
      }
    });

    region.show(collectionView);
  },

  saveProductionGroupPrinters(toastr = true) {
    DeviceConfig.setConfig(DeviceConfig.PATH_ProductionGroupPrinters, this.productionGroupPrinters);

    if (toastr) {
      Toaster.success(Locale.translate('production_group_printers_saved'));
    }
  },

  onRender() {
    this.renderProductionGroupCollection();
  },

  serializeData() {
    return {
      receiptPrinters: this.receiptPrinters,
      productionGroups: ProductionGroups.toJSON(),
    };
  },

}));
