define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/catalog/printMultiplePriceTags/layout.hbs',

  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',
  'modules/shop.cash-register-retail/models/settings/stickerPrinter',

], (
  $, _, Backbone, Template,
  PriceTagPrinter, StickerPrinter,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  ui: {
    barcodes: '#barcodes',
  },

  events: {
    'click [data-action="start-printing"]': 'startPrintingClicked',
    'click [data-action="start-printing-stickers"]': 'startPrintingStickersClicked',
  },

  startPrintingClicked() {
    const barcodes = this.ui.barcodes.val();
    const barcodesArray = barcodes.split('\n');

    if (barcodes.length > 0) {
      this.triggerMethod('layout:swap', 'printing', {
        barcodes: barcodesArray,
        type: 'pricetag',
      });
    }
  },

  startPrintingStickersClicked() {
    const barcodes = this.ui.barcodes.val();
    const barcodesArray = barcodes.split('\n');

    if (barcodes.length > 0) {
      this.triggerMethod('layout:swap', 'printing', {
        barcodes: barcodesArray,
        type: 'sticker',
      });
    }
  },

  onShow() {
    const self = this;
    setTimeout(() => {
      self.ui.barcodes.focus();
    }, 100);
  },

  serializeData() {
    return {
      showSticker: !!StickerPrinter.get('id'),
      showPriceTag: !!PriceTagPrinter.get('id'),
    };
  },
}));
