define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/productListWithSearch/list/resultCollection',

  './resultCollectionItem',
  './resultCollectionEmpty',
], (
  $, _, Backbone, Template,
  ItemView, EmptyView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childViewContainer: 'tbody',

  filter(child, index, collection) {
    return child.has('product_price.id');
  },

  childView: ItemView,

  emptyView: EmptyView,

  childEvents: {
    'product:selected': 'productSelected',
  },

  productSelected(__, model) {
    this.trigger('product:selected', model);
  },

  events: {
    'click [data-action=live-search]': 'liveSearch',
  },

  liveSearch() {
    this.triggerMethod('live:search');
  },

  serializeData() {
    return {
      onlyOrderable: this.options.onlyOrderable,
    };
  },
}));
