define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/posSettings/configureProductionGroups/popup',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/promisify',

  'modules/shop.cash-register-retail/collections/loadable/productionGroups',

  './layout',
], (
  $, _, PopupView, Template,
  CashRegisterApi, Toaster, Promisify,
  ProductionGroups,
  LayoutView,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--table-add-popup',

  events: {
    'click [data-action="close"]': 'closeClicked',
  },

  regions: {
    tableOverview: '[data-region=table-overview]',
  },

  async renderSettings() {
    const def = this.startLoader();

    try {
      // Check if there are any updates
      await ProductionGroups.loadPromise({ reload: true });

      def.resolve();
    } catch (err) {
      console.error(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }

    const region = this.getRegion('tableOverview');
    const view = new LayoutView({
      receiptPrinters: this.receiptPrinters,
    });
    region.show(view);
  },

  open({ receiptPrinters }) {
    this.receiptPrinters = receiptPrinters;

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/posSettings/configureProductionGroups/popup',
    });

    this.renderInFloatingRegion();

    this.openPopup();

    // Render the content here to ensure the loader is shown.
    this.renderSettings();
  },

}));
