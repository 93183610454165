define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cellWithKeyboard',
  'modules/shop.cash-register-retail/templates/products/list/cellReturnQuantity',
], (
  $, _, Backbone, CellView, Template,
) => CellView.extend({

  template: Template,

  className() {
    return `${this.model.get('id')} text-center`;
  },

  initialize(options) {
    options = options || {};

    this.productModel = options.productModel;
    this.keyboardMode = this.keyboardModel.MODE_RETURN_QUANTITY;

    // not turnover cannot be returned
    if (!this.productModel.get('is_turnover')) {
      options.isEditable = false;
    }

    this.previousReturnQuantity = 0;
    this.productModel.set('return_quantity', 0);

    CellView.prototype.initialize.call(this, options);
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberKey(key)) {
      const quantity = this.productModel.get('quantity');
      let newQuantity = key;
      if (this.firstInput) {
        this.firstInput = false;
      } else {
        const currentReturnQuantity = this.productModel.get('return_quantity');
        const newQuantityString = `${currentReturnQuantity}${key}`;
        newQuantity = parseInt(newQuantityString).toString(); // Fix 01 > 1
      }

      if (parseInt(newQuantity) > parseInt(quantity)) {
        newQuantity = quantity.toString();
      }

      this.productModel.set({
        return_quantity: newQuantity,
      });
    }
  },

  onModeSelected() {
    CellView.prototype.onModeSelected.call(this);
  },
  onModeDeselected() {
  },
  onConfirmation() {
    // no parent call cos breaks -> not in collection
    this.previousReturnQuantity = this.productModel.get('return_quantity');
  },

  onBackspacePress() {
    // Mark as changed
    this.firstInput = false;

    // Update the quantity
    let newQuantity = this.removeLastCharacterFromString(this.productModel.get('return_quantity') || '');
    if (newQuantity === '') newQuantity = '0';

    this.productModel.set('return_quantity', newQuantity);
  },

  onCancel() {
    this.productModel.setReturnQuantity(this.previousReturnQuantity, false);
  },

  onShow() {
    this.productModel.on('change:return_quantity', this.render, this);
  },

  onDestroy() {
    this.productModel.off('change:return_quantity', this.render, this);
  },

  serializeData() {
    return this.productModel.toJSON();
  },

}));
