define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/description/layout',

  'modules/common/components/currency',
  'modules/common/components/locale',
  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/views/products/description/description',
  'modules/shop.cash-register-retail/views/products/description/serials',
  'modules/shop.cash-register-retail/views/products/description/measurements',
  'modules/shop.cash-register-retail/components/scale',

  'modules/shop.cash-register-retail/views/popups/productInformationPopup',
  'modules/shop.cash-register-retail/components/feature.js',

  'modules/shop.common/collections/upx/NaturalSearchShopFlatProduct',
  'modules/shop.cash-register-retail/collections/orderItem.js',
], (
  $, _, Backbone, Template,
  Currency, Locale, Loader,
  DescriptionView, SerialsView, MeasurementsView, ScaleComponent,
  ProductInformationPopup, Feature,
  NaturalSearchShopFlatProductCollection, OrderItemCollection,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  className: 'product-description',

  events: {
    'click [data-action=close]': 'closeClicked',
    'click @ui.saveBtn': 'saveClicked',
    'click @ui.productDetailsBtn': 'productDetailsClicked',
    'keyup [data-action=order-description]': 'setOrderDescription',
  },

  ui: {
    saveBtn: '[data-action=save]',
    productDetailsBtn: '[data-ui=product-details]',
  },

  regions: {
    description: '[data-region="description"]',
    serials: '[data-region="serials"]',
    measurements: '[data-region="measurements"]',
    popup: '[data-region="popup"]',
  },

  initialize({ hideMeasurements = false }) {
    this.hideMeasurements = hideMeasurements;
  },

  setOrderDescription(e) {
    const $el = $(e.currentTarget);
    this.model.set('order_description', $el.val());
  },

  onRender() {
    this.descriptionView = this.renderView('description', DescriptionView);
    if (this.hasSerialNumbers()) {
      this.serialsView = this.renderView('serials', SerialsView);
    } else if (!this.hideMeasurements || this.needsWeightOnKassa()) {
      this.measurementsView = this.renderView('measurements', MeasurementsView);
    }
  },

  renderView(regionName, ViewCls) {
    const region = this.getRegion(regionName);
    const view = new ViewCls({
      model: this.model,
    });
    region.show(view);
    return view;
  },

  productDetailsClicked() {
    const def = this.loader.startLoader();

    const collection = new NaturalSearchShopFlatProductCollection();
    const params = {
      query: 0,
      lang: Locale.getLocale(),
      start: 0,
      limit: 1,
      filters: [{
        name: 'id__=',
        val: this.model.get('shop_product_id'),
      }],
    };

    collection.fetch({ params })
      .then(() => {
        const model = collection.first();
        if (!model) def.reject(Locale.translate('unable_to_find_product_information'));

        const region = this.getRegion('popup');
        const view = new ProductInformationPopup({ model });
        region.show(view);

        def.resolve();
      }, def.reject);
  },

  saveClicked() {
    const def = this.loader.startLoader();
    if (this.descriptionView) {
      this.descriptionView.saveClicked();
    }
    if (this.serialsView) {
      this.serialsView.saveClicked();
    }
    if (this.measurementsView) {
      this.measurementsView.saveClicked();
    }

    this.triggerMethod('view:closed');
    def.resolve();
  },

  needsWeightOnKassa() {
    return this.model.get('featured_attributes.needs_weight_on_kassa.value') === '1';
  },

  hasSerialNumbers() {
    return !!this.model.get('has_serial_numbers') && Feature.isProductSerialFeatureEnabled();
  },

  closeClicked() {
    this.triggerMethod('view:closed');
  },

  serializeData() {
    const showInfoButton = this.model.get('type') !== OrderItemCollection.prototype.TYPE_USED_GOODS;
    const name = this.model.get('name');

    return {
      showInfoButton,
      name,
    };
  },
}));
