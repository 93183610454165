define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './layout',

  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/collections/loadable/productionGroups',
], (
  $, _, Backbone, SwappableView,
  LayoutView,
  priceTagPrinterSetting, PaymentMethodsSetting, ProductionGroups,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(LayoutView);
  },

  loadSharedData(dataStorage) {
    const def = new $.Deferred();

    $.when(
      priceTagPrinterSetting.fetchPrinters(),
      PaymentMethodsSetting.load(true),
    ).then((printerResponse) => {
      dataStorage.printerCollection = printerResponse.printers;
      def.resolve();
    }, def.reject);

    return def;
  },

}));
