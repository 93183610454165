define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/blocks/categories/item.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'designer-box rounded',

  events: {
    click: 'handleClicked',
  },

  modelEvents: {
    'change:selected': 'selectedChanged',
  },

  handleClicked() {
    this.model.collection.each((model) => {
      model.set({
        selected: false,
      });
    });
    this.model.set('selected', true);
  },

  onShow() {
    this.selectedChanged();
  },

  selectedChanged() {
    if (this.model.get('selected')) {
      this.$el.addClass('selected');
    } else {
      this.$el.removeClass('selected');
    }
  },

}));
