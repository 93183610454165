define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/catalog/listItem',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/views/popups/productInformationPopup',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/components/productCache',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',
  'modules/shop.cash-register-retail/models/settings/stickerPrinter',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'modules/shop.cash-register-retail/views/popups/quantityPopup',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',

  'modules/shop.cash-register-retail/components/addonItem',
], (
  $, _, Backbone, Template, Upx, ProductInformationPopup, DeviceConfig, Toaster, Locale,
  PrintingComponent, ProductCacheComponent, MessagePopup, Loader,
  PriceTagPrinter, StickerPrinter, CurrentOrderItemCollection,
  QuantityPopupView, ConfirmPopupView,
  AddonItem,
) => Backbone.Marionette.LayoutView.extend({

  PRINT_STICKER: 'print-sticker',
  PRINT_TAG: 'print-tag',

  template: Template,

  tagName: 'tr',

  events: {
    'click [data-action="details"]': 'detailsClicked',
    'touchend [data-action="details"]': 'detailsClicked',

    'click [data-action="print-tag"]': 'printTagClicked',
    'touchend [data-action="print-tag"]': 'printTagClicked',

    'click [data-action="print-sticker"]': 'printStickerClicked',
    'touchend [data-action="print-sticker"]': 'printStickerClicked',

    'click [data-action="add"]': 'addClicked',
    'touchend [data-action="add"]': 'addClicked',
  },

  regions: {
    popup: '[data-region="popup"]',
  },

  ui: {
    print: '[data-ui="print"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  detailsClicked: _.debounce(function () {
    const region = this.getRegion('popup');
    const view = new ProductInformationPopup({
      model: this.model,
    });
    region.show(view);
  }, 100),

  addClicked: _.debounce(function () {
    Backbone.history.navigate('checkout', { trigger: true });
    // Delay needed to make sure the page has been swapped.
    setTimeout(() => {
      CurrentOrderItemCollection.addProductByShopFlatProductModel(this.model, 1);
      // update the cache for added product
      ProductCacheComponent.storeShopProduct(this.model);
    }, 100);
  }, 100),

  printStickerClicked: _.debounce(function () {
    this.printType(this.PRINT_STICKER);
  }, 100),

  printTagClicked: _.debounce(function () {
    this.printType(this.PRINT_TAG);
  }, 100),

  printType(printType) {
    const def = this.loader.startLoader();

    // Locking the print button
    this.model.collection.trigger('lock:print');
    def.always(() => {
      // collection won't exists if there was another search.
      if ('collection' in this.model) {
        this.model.collection.trigger('unlock:print');
      }
    });

    // Get quantity
    const view = new QuantityPopupView();
    view.open(1)
      .then((wantedQuantity) => {
        // Check if the wantedQuantity is high
        const confirmDef = new $.Deferred();
        if (wantedQuantity <= 20) {
          confirmDef.resolve();
        } else {
          // Open delay needed to make sure the quantity popup is closed
          setTimeout(() => {
            const confirmView = new ConfirmPopupView();
            confirmView.open(
              Locale.translate('printing_{0}_stickers', wantedQuantity),
              Locale.translate('are_you_sure_you_want_to_print_lessstronggreater{0}_stickerslessstronggreater_question', wantedQuantity),
              confirmDef,
            );
          });
        }

        confirmDef.then(() => {
          // Create print collection
          const collection = new Backbone.Collection();
          for (let i = 0; i < wantedQuantity; i++) {
            collection.add({
              productModel: this.model,
              status: 'new',
              printType,
              message: null,
            });
          }

          // Process print by type
          this.processPrints(collection)
            .then(() => {
              Toaster.success(Locale.translate('done_printing'));
              def.resolve();
            }, def.reject);
        }, def.reject);
      }, def.reject);

    return def;
  },

  processPrints(collection, def, index) {
    def = def || new $.Deferred();
    index = index || 0;

    // Check if there is a model at a certain index
    const model = collection.at(index);
    if (model) {
      const productModel = model.get('productModel');
      const printType = model.get('printType');

      // Getting the printing deferred
      let printDef = new $.Deferred();
      if (printType === this.PRINT_STICKER) {
        printDef = PrintingComponent.printPriceSticker(productModel);
      } else if (printType === this.PRINT_TAG) {
        printDef = PrintingComponent.printPriceTag(productModel);
      } else {
        // If type does not exists. we just skip it
        printDef.resolve();
        console.warn(`Print type ${printType} does not exists.`);
      }

      // Waiting for the printer.
      printDef.then(() => {
        model.set('status', 'done');
        this.processPrints(collection, def, ++index);
      }, def.reject);
    } else {
      // Done processing items
      const errorArr = collection.where({ status: 'error' });
      if (errorArr.length > 0) {
        def.reject();
      } else {
        def.resolve();
      }
    }

    return def;
  },

  onShow() {
    this.model.collection.on('lock:print', this.lockPrint, this);
    this.model.collection.on('unlock:print', this.unlockPrint, this);
  },

  onDestroy() {
    this.model.collection.off('lock:print', this.lockPrint, this);
    this.model.collection.off('unlock:print', this.unlockPrint, this);
  },

  lockPrint() {
    this.ui.print.attr('disabled', true);
  },

  unlockPrint() {
    this.ui.print.attr('disabled', false);
  },

  getProductImage() {
    if (this.model.has('flat_product.main_image.url')) {
      return Upx.getResourceUrl(this.model.get('flat_product.main_image.url'));
    }
    return './images/no-product-image.png';
  },

  getProductAttributes() {
    const wantedAttributes = [];

    if (this.model.has('flat_product.content_vars')) {
      const productCatalogAttributeModel = DeviceConfig.getConfig(DeviceConfig.PATH_ProductCatalogAttributes);
      if (!productCatalogAttributeModel) {
        return false;
      }
      const wantedAttributeNames = productCatalogAttributeModel.get('extra.names');
      if (!wantedAttributeNames) {
        return false;
      }
      const contentVars = this.model.get('flat_product.content_vars');
      contentVars.forEach((object) => {
        if (_.contains(wantedAttributeNames, object.name)) {
          const model = new Backbone.Model(object);
          let attributeName = model.get('name');
          let attributeValue = model.get('value');
          if (model.has('label')) {
            attributeName = model.get('label');
          }
          if (model.has('value_label')) {
            attributeValue = model.get('value_label');
          }
          wantedAttributes.push({
            name: attributeName,
            value: attributeValue,
          });
        }
      });
    }

    return wantedAttributes;
  },

  showAttributes() {
    const productCatalogAttributeModel = DeviceConfig.getConfig(DeviceConfig.PATH_ProductCatalogAttributes);
    if (!productCatalogAttributeModel) {
      return false;
    }
    const wantedAttributeNames = productCatalogAttributeModel.get('extra.names');
    if (!wantedAttributeNames) {
      return false;
    }
    return true;
  },

  serializeData() {
    const data = this.model.toJSON();
    data.product_image = this.getProductImage();
    data.product_attributes = this.getProductAttributes();
    data.has_product_attributes = this.showAttributes();
    data.has_sticker_printer = StickerPrinter.isWantedType();
    data.has_pricetag_printer = PriceTagPrinter.isWantedType();
    return data;
  },

}));
