define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/catalog/layout.hbs',

  'modules/shop.cash-register-retail/views/products/catalog/list',

  'modules/shop.common/collections/upx/NaturalSearchShopFlatProduct',
  'modules/shop.cash-register-retail/views/popups/printMultiplePriceTagsPopup',
  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',
  'modules/shop.cash-register-retail/models/settings/stickerPrinter',

  'modules/common/components/locale',
  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Template,
  ListView,
  NaturalSearchShopFlatProductCollection, PrintMultiplePriceTagsPopupView, PriceTagPrinter, StickerPrinter,
  Locale, Loader, Keyboard,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'products-catalog',

  regions: {
    products: '[data-region="products"]',
    printMultiplePriceTags: '[data-region="print-multiple-price-tags"]',
  },

  events: {
    'submit [data-action="form"]': 'searchChanged',

    'click [data-action="more"]': 'moreClicked',
    'touchend [data-action="more"]': 'moreClicked',

    'click [data-action="clear"]': 'clearClicked',
    'touchend [data-action="clear"]': 'clearClicked',

    'click [data-action="print-multiple"]': 'printMultipleClicked',
    'touchend [data-action="print-multiple"]': 'printMultipleClicked',
  },

  ui: {
    search: '[data-ui="search"]',
    more: '[data-ui="more"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize() {
    this.collection = new NaturalSearchShopFlatProductCollection();
  },

  printMultipleClicked() {
    const view = new PrintMultiplePriceTagsPopupView();
    view.open();
  },

  moreClicked: _.debounce(function () {
    if (this.collection.canFetchNext()) {
      const def = this.loader.startLoader('more');
      this.collection.fetchNext()
        .always(() => {
          this.checkMore();
          def.resolve();
        });
    }
  }, 500),

  clearClicked() {
    this.ui.search.val('');
    this.ui.search.trigger('keyup');
    this.ui.search.trigger('change');
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();
  },

  checkMore() {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  },

  updateCollection() {
    const def = this.loader.startLoader('main');
    const value = this.ui.search.val();
    let query = value.trim();
    if (query.length <= 0) {
      query = 0;
    }

    const isEmpty = value.trim() === '';

    const dateCreatedSort = {
      name: 'date_created',
      dir: 'desc',
    };

    const params = {
      params: {
        query,
        lang: Locale.getLocale(),
        start: 0,
        limit: 20,
        sort: isEmpty ? [dateCreatedSort] : undefined,
        filters: [{
          name: 'product_price/ppu_wt__notnull',
          val: true,
        }],
      },
    };

    this.collection.fetch(params)
      .always(() => {
        this.renderItems();
        this.checkMore();
        def.resolve();
      });
  },

  onShow() {
    // Hide the check button
    this.ui.more.hide();
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();

    // Make sure the initial mode is set correctly.
    Keyboard.setViewWithMode(this.cid, Keyboard.MODE_PRODUCT_CATALOG_SEARCH);

    // Register an interval that registers the mode when the mode is neustral
    this.keyboardInterval = setInterval(() => {
      if (Keyboard.isNeutralMode()) {
        Keyboard.setViewWithMode(this.cid, Keyboard.MODE_PRODUCT_CATALOG_SEARCH);
      }
    }, 100);

    // Search when enter is pressed on the keyboard
    Keyboard.on('key:confirm', this.searchChanged, this);
  },

  onDestroy() {
    Keyboard.off('key:confirm', this.searchChanged, this);
    clearInterval(this.keyboardInterval);
    Keyboard.resetMode();
  },

  searchChanged: _.debounce(function () {
    if (Keyboard.isViewAndMode(this.cid, Keyboard.MODE_PRODUCT_CATALOG_SEARCH)) {
      this.updateCollection();
    }
  }, 750),

  onRender() {
    this.renderItems();
  },

  renderItems() {
    if (!this.isDestroyed && this.ui.search.get(0) && this.ui.search.val().length !== 0) {
      const region = this.getRegion('products');
      const view = new ListView({
        collection: this.collection,
      });
      region.show(view);
    }
  },

  serializeData() {
    return {
      hasLpPrinter: !!PriceTagPrinter.get('id') || !!StickerPrinter.get('id'),
    };
  },

}));
