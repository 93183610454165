define([
  'jquery',
  'underscore',
  'backbone',
  './item',
  './itemFull',
  'modules/shop.cash-register-retail/templates/products/blocks/products/collection.hbs',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, ItemView, ItemFullView, Template,
  Loader,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  className() {
    if (this.options.type === 'full') {
      return 'products-container-full';
    }
    return 'products-container';
  },

  behaviors: {
    Loader: {
		        behaviorClass: Loader,
		        name: 'product-block',
		    },
  },

  childViewContainer: '[data-region="collection"]',

  childView: ItemView,

  ui: {
    loadAll: '[data-ui="load-all"]',
  },

  childEvents: {
    'child:clicked': 'childClicked',
  },

  events: {
    'click @ui.loadAll': 'onLoadAll',
  },

  getChildView() {
    if (this.options.type === 'full') {
      return ItemFullView;
    }
    return ItemView;
  },

  childClicked(view, model) {
    this.triggerMethod('child:clicked', model);
  },

  initialize() {
    this.model = new Backbone.Model();
    this.categoryId = this.options.categoryId;
    this.searchModel = this.options.searchModel;
    this.limit = 120;
    this.loadAll = false;
  },

  onShow() {
    this.searchModel.on('change:value', this.render, this);
  },

  onLoadAll() {
    this.loadAll = true;
    this.loader.startLoader();

    // Give the loader some time to spawn
    setTimeout(() => {
      this.render();
    }, 0);
  },

  onBeforeRender() {
    this.lastRenderIndex = 0;
  },

  onRender() {
    if (this.lastRenderIndex === this.limit) {
      this.ui.loadAll.show();
    } else {
      this.ui.loadAll.hide();
    }
  },

  onDestroy() {
    this.searchModel.off('change:value', this.render, this);
  },

  search(title) {
    title = title.toLowerCase();
    if (this.searchModel) {
      const query = this.searchModel.get('value');
      if (query !== '') {
        return title.indexOf(query.toLowerCase()) !== -1;
      }
    }

    return true;
  },

  shouldBeShown(childModel) {
    const title = childModel.get('flat_product.title') || '';
    if (this.categoryId) {
      const categories = childModel.get('flat_product.categories');
      if (categories) {
        const category_ids = _.pluck(categories, 'id');
        return category_ids.indexOf(this.categoryId) !== -1 && this.search(title);
      }
      return false;
    }
    return this.search(title);
  },

  filter(childModel) {
    if (!this.loadAll && this.lastRenderIndex >= this.limit) {
      return false;
    }
    const model = this.shouldBeShown(childModel);
    if (model) {
      this.lastRenderIndex++;
      return true;
    }
    return false;
  },

}));
