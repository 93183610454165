define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/layout.hbs',

  'modules/common/components/locale',
  'modules/shop.common/components/mode',
  'modules/shop.common/components/version',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/components/backoffice',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/restaurantCache',

  'modules/shop.cash-register-retail/components/toaster',

  'modules/profile/models/profile',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/models/settings/terminal',
  'modules/shop.cash-register-retail/models/settings/scaleType',
  'modules/shop.cash-register-retail/models/settings/keyboardLayout',

  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/models/settings/stickerPrinter',
  'modules/shop.cash-register-retail/models/settings/mediaType',

  'modules/shop.cash-register-retail/models/customerScreenData',

  './dropdownSetting',
  './printerCollection',
  './salesChannel',
  './paymentMethod',
  './receiptExample',
  './webReceiptSetting',
  './configureProductionGroups/popup',
  './receiptPrinterSetting',

  'modules/shop.cash-register-retail/components/onlineFoodOrder',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/posSettings/technician/welcomePopup',
  'modules/shop.cash-register-retail/views/posSettings/technician/actionsPopup',
  'modules/shop.cash-register-retail/models/ccv/ccvPin',
], (
  $, _, Backbone, Template,
  Locale, ModeComponent, Version, DeviceConfig, Backoffice, CashRegisterApi, RestaurantCache,
  Toaster,
  ProfileModel,
  PaymentMethodsSetting, TerminalSetting, ScaleTypeSetting, KeyboardLayoutSetting,
  PriceTagPrinterSetting, ReceiptPrinterSetting, StickerPrinterSetting, MediaTypeSetting,
  CustomerScreenData,
  DropdownSettingView, PrinterCollection, SalesChannel, PaymentMethod, ReceiptExample, WebReceiptSetting, ConfigureProductionGroupsPopup, ReceiptPrinterSettingView,
  OnlineFoodOrder, MessagePopup, TechnicianWelcomePopup, TechnicianActionsPopup, CCVPin
) => Backbone.Marionette.LayoutView.extend({

  className: 'pos-settings',

  template: Template,

  childEvents: {
    'setting:updated': 'settingUpdated',
  },

  ui: {
    testSpeakerBtn: '[data-action=test-speaker]',
    soundSection: '[data-ui=sound-section]',
    deviceInfo: '[data-ui="device-info"]',
  },

  events: {
    'click [data-action=reload]': 'reloadClicked',
    'click [data-action=production-groups]': 'productionGroupsClicked',
    'click @ui.testSpeakerBtn': 'testSpeaker',
    'mousedown @ui.deviceInfo': 'deviceInfoClicked',
    'mouseup @ui.deviceInfo': 'deviceInfoReleased',
    'mouseleave @ui.deviceInfo': 'deviceInfoReleased',
    'touchstart @ui.deviceInfo': 'deviceInfoClicked',
    'touchend @ui.deviceInfo': 'deviceInfoReleased',
    'touchcancel @ui.deviceInfo': 'deviceInfoReleased',
  },

  regions: {
    pinDevice: '[data-region=pin-device]',
    receiptPrinter: '[data-region=receipt-printer]',
    printerSettings: '[data-region=printer-settings]',
    scale: '[data-region=scale]',
    keyboardLayout: '[data-region=keyboard-layout]',
    salesChannel: '[data-region=sales-channel]',
    paymentMethods: '[data-region=payment-methods]',
    receiptExample: '[data-region=receipt-example]',
  },

  initialize(options) {
    this.printerCollection = options.printerCollection;

    // Main settings model
    this.model = new Backbone.DeepModel({
      pinTerminalId: TerminalSetting.get('id'),
      scaleType: ScaleTypeSetting.getIdOrDefault(),
      keyboardLayout: KeyboardLayoutSetting.getIdOrDefault(),
      // Printers
      receiptPrinterId: ReceiptPrinterSetting.get('id'),
      receiptPrinterType: ReceiptPrinterSetting.get('type'),
      shelveCardPrinterId: PriceTagPrinterSetting.get('id'),
      stickerPrinterId: StickerPrinterSetting.get('id'),
      stickerPrinterMediaType: MediaTypeSetting.get('id'),
    });
    this.technicianModeTimeout = null;
  },

  getAccount() {
    const backofficeUrl = new URL(Backoffice.getBackofficeUrl());
    const backofficeHostnameSplited = backofficeUrl.hostname.split('.');
    const account = backofficeHostnameSplited[0];
    const location = backofficeHostnameSplited.splice(1).join('.');
    return `${account} (${location})`;
  },

  deviceInfoClicked() {
    this.deviceInfoReleased();
    this.technicianModeTimeout = setTimeout(
      () => this.onTechnicianModePopup(),
      5000, // 5s
    );
  },
  deviceInfoReleased() {
    if (this.technicianModeTimeout !== null) {
      clearTimeout(this.technicianModeTimeout);
    }
  },

  onTechnicianModePopup() {
    const welcomeView = new TechnicianWelcomePopup();
    welcomeView.open().then(
      () => {
        setTimeout(() => {
          const view = new TechnicianActionsPopup();
          view.open();
        }, 100);
      },
    );
  },

  testSpeaker() {
    const message = this.ui.testSpeakerBtn.find('[data-ui="message"]');
    this.ui.testSpeakerBtn.prop('disabled', true);
    message.text(` ... ${Locale.translate('playing_sound')}`);
    OnlineFoodOrder.playSound();
    setTimeout(() => {
      message.text('');
      this.ui.testSpeakerBtn.prop('disabled', false);
    }, 5000);
  },

  serializeData() {
    return {
      showProductionGroup: !ModeComponent.isInWebMode() && !ModeComponent.isInAppMode() && DeviceConfig.getDeviceMode() === DeviceConfig.MODE_Hospitality,
      deviceId: DeviceConfig.getDeviceUUID,
      version: Version.getVersion(),
      account: this.getAccount(),
      user: ProfileModel.getFullProfileName(),
    };
  },

  onRender() {
    if (PaymentMethodsSetting.pinEnabled() || PaymentMethodsSetting.pinExtraEnabled()) {
      this.renderPinDevice();
    }
    this.renderPaymentMethod();
    this.renderSalesChannel();
    this.renderReceiptExample();

    if (ModeComponent.isInAppMode()) {
      // APP
      this.renderNetworkReceiptPrinter();
    } else if (ModeComponent.isInWebMode()) {
      // WEB
      this.renderWebReceiptPrinter();
    } else if (ModeComponent.isInElectronMode()) {
      // ELECTRON
      this.renderReceiptPrinter();
    } else {
      // NO WEB OR APP
      this.renderReceiptPrinter();
      this.renderPrinters();
      this.renderScale();
      this.renderKeyboardLayout();
    }

    if (!OnlineFoodOrder.isEnabled()) {
      this.ui.soundSection.hide();
    }
  },

  renderPinDevice() {
    const region = this.getRegion('pinDevice');
    const view = new DropdownSettingView({
      model: this.model,
      targetPath: 'pinTerminalId',
      label: Locale.translate('pin_terminal'),
      notSelectedLabel: Locale.translate('select_a_pin_terminal'),
      autoSelect: false,
      items: PaymentMethodsSetting.getTerminalDevicesCollection()
        .map((model) => ({
          id: model.get('id'),
          label: model.get('title'),
        })),
    });
    region.show(view);
  },

  getReceiptPrinters() {
    const receiptPrinters = [];

    this.printerCollection.each((model) => {
      const type = model.get('type');
      if (type === ReceiptPrinterSetting.PRINTER_TYPE_ESCPOS) {
        receiptPrinters.push({
          label: model.has('name') ? model.get('name') : model.get('id'),
          id: model.get('id'),
        });
      }
    });
    return receiptPrinters;
  },

  renderReceiptPrinter() {
    const printerArray = this.getReceiptPrinters();

    const region = this.getRegion('receiptPrinter');
    const view = new ReceiptPrinterSettingView({
      model: this.model,
      targetPathId: 'receiptPrinterId',
      targetPathType: 'receiptPrinterType',
      printers: printerArray,
      showLabel: true,
    });

    region.show(view);
  },

  renderWebReceiptPrinter() {
    const region = this.getRegion('receiptPrinter');
    const view = new WebReceiptSetting({
      model: this.model,
      targetPath: 'receiptPrinterId',
      label: Locale.translate('receipt_printer'),
    });
    region.show(view);
  },

  renderNetworkReceiptPrinter() {
    const region = this.getRegion('receiptPrinter');
    const view = new ReceiptPrinterSettingView({
      model: this.model,
      targetPathId: 'receiptPrinterId',
      targetPathType: 'receiptPrinterType',
      availableTypes: ['network'],
      showLabel: true,
    });
    region.show(view);
  },

  renderPrinters() {
    const printerArray = [];
    this.printerCollection.each((model) => {
      const type = model.get('type');
      if (type === PriceTagPrinterSetting.PRINTER_TYPE_ZPL) {
        printerArray.push({
          label: model.has('name') ? model.get('name') : model.get('id'),
          id: model.get('id'),
        });
      }
    });

    const region = this.getRegion('printerSettings');
    const view = new PrinterCollection({
      model: this.model,
      collection: new Backbone.Collection(printerArray),
    });
    region.show(view);
  },

  renderScale() {
    const region = this.getRegion('scale');
    const view = new DropdownSettingView({
      model: this.model,
      targetPath: 'scaleType',
      label: Locale.translate('scale'),
      items: ScaleTypeSetting.getFullCollection()
        .map((model) => ({
          id: model.get('id'),
          label: model.get('name'),
        })),
    });
    region.show(view);
  },

  renderKeyboardLayout() {
    const region = this.getRegion('keyboardLayout');
    const view = new DropdownSettingView({
      model: this.model,
      targetPath: 'keyboardLayout',
      label: Locale.translate('keyboard_layout'),
      items: KeyboardLayoutSetting.getFullCollection()
        .map((model) => ({
          id: model.get('id'),
          label: model.get('name'),
        })),
    });
    region.show(view);
  },

  renderSalesChannel() {
    const region = this.getRegion('salesChannel');
    const view = new SalesChannel();
    region.show(view);
  },

  renderPaymentMethod() {
    const region = this.getRegion('paymentMethods');
    const view = new PaymentMethod();
    region.show(view);
  },

  renderReceiptExample() {
    const region = this.getRegion('receiptExample');
    const view = new ReceiptExample({
      model: this.model,
    });
    region.show(view);
  },

  reloadClicked() {
    CashRegisterApi.logAction('RELOAD_BUTTON_CLICKED', { button: 'version mis match reload' });
    CustomerScreenData.reloadCustomerScreen();
    location.reload(true);
  },

  productionGroupsClicked() {
    (new ConfigureProductionGroupsPopup()).open({
      receiptPrinters: this.getReceiptPrinters(),
    });
  },

  settingUpdated(__, updatedSetting) {
    CashRegisterApi.logAction('SETTING_CHANGING', { setting: updatedSetting, id: this.model.get('updatedSetting') });

    switch (updatedSetting) {
      case 'pinTerminalId': this.updatePinTerminal(); break;
      case 'scaleType': this.updateScaleType(); break;
      case 'keyboardLayout': this.updateKeyboardLayout(); break;
      // Printers
      case 'receiptPrinterId': this.updateReceiptPrinter(); break;
      case 'shelveCardPrinterId': this.updateShelveCardPrinter(); break;
      case 'stickerPrinterId': this.updateStickerPrinter(); break;
      case 'stickerPrinterMediaType': this.updateStickerPrinterMedia(); break;
    }
  },

  updatePinTerminal() {
    const model = PaymentMethodsSetting.getTerminalDevicesCollection()
      .get(this.model.get('pinTerminalId'));

    const wasCCV = TerminalSetting.isCCVPin();
    TerminalSetting.destroy();
    if (model) TerminalSetting.setTerminalByModel(model);

    const def = this.saveSettings();

    let ccvPinDef;
    if (!model && wasCCV && CCVPin.isAvailable()) {
      ccvPinDef = CCVPin.removePinTerminal();
    } else if (model && TerminalSetting.isCCVPin() && CCVPin.isAvailable()) {
      ccvPinDef = CCVPin.updatePinTerminal();
    }

    $.when(
      ccvPinDef,
      DeviceConfig.setConfig(
        DeviceConfig.PATH_PinTerminal,
        {
          title: TerminalSetting.get('title'),
          id: TerminalSetting.get('id'),
        },
      ),
    ).then(def.resolve, def.reject);

    // .then(def.resolve, def.reject);
  },

  updateScaleType() {
    const model = ScaleTypeSetting.getFullCollection()
      .get(this.model.get('scaleType'));

    ScaleTypeSetting.destroy();
    if (model) ScaleTypeSetting.setByModel(model);

    const def = this.saveSettings();
    DeviceConfig.setConfig(
      ScaleTypeSetting.configPath,
      {
        id: ScaleTypeSetting.get('id'),
        type: ScaleTypeSetting.get('name'),
      },
    ).then(def.resolve, def.reject);
  },

  updateKeyboardLayout() {
    const model = KeyboardLayoutSetting.getFullCollection()
      .get(this.model.get('keyboardLayout'));

    KeyboardLayoutSetting.destroy();
    if (model) KeyboardLayoutSetting.setByModel(model);

    const def = this.saveSettings();
    DeviceConfig.setConfig(
      KeyboardLayoutSetting.configPath,
      {
        id: KeyboardLayoutSetting.get('id'),
        type: KeyboardLayoutSetting.get('name'),
      },
    ).then(def.resolve, def.reject);
  },

  updateReceiptPrinter() {
    const printerId = this.model.get('receiptPrinterId');
    const printerType = this.model.get('receiptPrinterType');

    if (printerId && printerType) {
      ReceiptPrinterSetting.setPrinter(printerId, printerType);
    } else {
      ReceiptPrinterSetting.resetPrinter();
    }

    this.savePrinterSetting(ReceiptPrinterSetting);
  },

  updateShelveCardPrinter() {
    const printerId = this.model.get('shelveCardPrinterId');
    const model = this.printerCollection.get(printerId);

    if (model) {
      // Set and calibrate
      PriceTagPrinterSetting.setPrinter(printerId, model.get('type'));
      this.calibratePrinter(printerId, PriceTagPrinterSetting.PRINTER_TYPE_SHELF_CARD);
    } else {
      PriceTagPrinterSetting.resetPrinter();
    }

    this.savePrinterSetting(PriceTagPrinterSetting);
  },

  updateStickerPrinter() {
    const printerId = this.model.get('stickerPrinterId');
    const model = this.printerCollection.get(printerId);

    if (model) {
      // Set and calibrate
      StickerPrinterSetting.setPrinter(printerId, model.get('type'));
      this.calibratePrinter(printerId, PriceTagPrinterSetting.PRINTER_TYPE_PRICE_STICKER);
    } else {
      StickerPrinterSetting.resetPrinter();
    }

    this.savePrinterSetting(StickerPrinterSetting);
  },

  updateStickerPrinterMedia() {
    const model = MediaTypeSetting.getFullCollection()
      .get(this.model.get('stickerPrinterMediaType'));

    MediaTypeSetting.destroy();
    if (model) {
      MediaTypeSetting.setByModel(model);

      // Recalibrate the printer
      const printerId = StickerPrinterSetting.get('id');
      if (printerId) {
        this.calibratePrinter(printerId, PriceTagPrinterSetting.PRINTER_TYPE_PRICE_STICKER);
      }
    }

    DeviceConfig.setConfig(
      MediaTypeSetting.configPath,
      {
        id: MediaTypeSetting.get('id'),
        type: MediaTypeSetting.get('name'),
      },
    );
  },

  calibratePrinter: _.debounce((printerId, type) => {
    CashRegisterApi.call(`/zpl-printers/${printerId}/calibrate/${type}`, 'POST')
      .fail((resp) => {
        new MessagePopup()
          .open(Locale.translate('could_not_calibrate_the_printer_{0}', [resp.error]));
      });
  }, 100),

  savePrinterSetting(printerSetting) {
    const def = this.saveSettings();
    DeviceConfig.setConfig(
      printerSetting.configPath,
      {
        id: printerSetting.get('id'),
        type: printerSetting.get('type'),
      },
    ).then(def.resolve, def.reject);
  },

  saveSettings() {
    const def = new $.Deferred();

    Toaster.info(`${Locale.translate('saving_settings')}..`);

    def.then(
      () => this.settingsSaved(),
      (resp) => {
        // If resp is empty, we saved successfully
        if (resp && 'error' in resp) {
          Toaster.error(`${Locale.translate('error_while_saving_settings')}: ${resp.error}`);
        } else {
          this.settingsSaved();
        }
      },
    );

    return def;
  },

  settingsSaved() {
    Toaster.success(`${Locale.translate('settings_saved')}!`);
  },

}));
