define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Locale,
  WithKeyboardBehaviour, KeyboardModel,
) => Backbone.Marionette.LayoutView.extend({

  template() {}, // No template needed.

  tagName: 'textarea',

  className: 'form-control',

  attributes() {
    return {
      placeholder: Locale.translate('serial_numbers'),
      rows: '2',
    };
  },

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
      resetOnConfirmation: false,
      resetOnCancel: false,
    },
  },

  events: {
    keyup: 'inputChanged',
  },

  inputChanged() {
    this.inputValue = this.$el.val();
  },

  initialize() {
    this.keyboardMode = KeyboardModel.MODE_PRODUCT_SERIALS;
  },

  onShow() {
    this.inputValue = (this.model.get('serial_nos') || []).join(' ');
    this.setValue();
  },

  setValue() {
    this.$el.val(this.inputValue);
  },
  getValue() {
    return this.inputValue;
  },

  onKeyPress(key) {
  },
  onBackspacePress() {
  },

  onModeDeselected() {
  },

  onConfirmation() {
  },

  onModeSelected() {
  },

  onCancel() {
  },

}));
