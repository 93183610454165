define([
  'jquery',
  'underscore',
  'backbone',

  './item',
], (
  $, _, Backbone,
  ItemView,
) => Backbone.Marionette.CollectionView.extend({

  childView: ItemView,

  childViewOptions() {
    return {
      printers: this.printers,
      productionGroupPrinters: this.productionGroupPrinters,
    };
  },

  childEvents: {
    'child:printer:update': 'onPrinterChange',
  },

  onPrinterChange(model, productionGroupId, printerModel) {
    this.triggerMethod('printer:update', productionGroupId, printerModel);
  },

  initialize({ printers, productionGroupPrinters }) {
    this.printers = printers;
    this.productionGroupPrinters = productionGroupPrinters;
  },

}));
