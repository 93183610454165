define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/webReceiptSetting.hbs',

  './webReceiptButton',
], (
  $, _, Backbone, Template,
  WebReceiptButton,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  defaults: {
    targetPath: null,
    label: null,
  },

  initialize(options) {
    this.options = _.extend({}, this.defaults, options);
  },

  regions: {
    button: '[data-region=button]',
  },

  onRender() {
    const region = this.getRegion('button');
    const view = new WebReceiptButton({
      model: this.model,
      targetPath: this.options.targetPath,
    });
    region.show(view);
  },

  childEvents: {
    'setting:updated': function (__, targetPath) {
      this.triggerMethod('setting:updated', targetPath);
    },
  },

  serializeData() {
    return {
      label: this.options.label,
    };
  },

}));
