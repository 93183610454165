define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/catalog/list',
  './listItem',
  './listEmpty',

  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',

], (
  $, _, Backbone, Template, ItemView, EmptyView,
  DeviceConfig, PriceTagPrinter,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childView: ItemView,

  emptyView: EmptyView,

  childViewContainer: 'tbody',

  showAttributes() {
    const productCatalogAttributeModel = DeviceConfig.getConfig(DeviceConfig.PATH_ProductCatalogAttributes);
    if (!productCatalogAttributeModel) {
      return false;
    }
    const wantedAttributeNames = productCatalogAttributeModel.get('extra.names');
    if (!wantedAttributeNames) {
      return false;
    }
    return true;
  },

  serializeData() {
    return {
      has_cups_printer: PriceTagPrinter.isWantedType(),
      showAttributes: this.showAttributes(),
    };
  },

}));
