define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/paymentMethod',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',
], (
  $, _, Backbone, Template,
  PaymentMethodsSetting,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'row',

  serializeData() {
    return {
      paymentMethods: PaymentMethodsSetting.getAllowedPaymentMethodCollection().toJSON(),
    };
  },

}));
