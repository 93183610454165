define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/posSettings/technician/actionsPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/components/toaster',

], (
  $, _, Backbone, PopupView, Template, Locale, CashRegisterApi,
  Upx, ConfirmPopupView, Toaster,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--simple',

  ui: {
    close: '[data-action="close"]',
    resetPos: '[data-ui="reset-pos"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'click @ui.resetPos': 'resetPosClicked',
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    this.logOpenAction('modules/shop.cash-register-retail/views/posSettings/technician/actionsPopup.js');

    this.openPopup();
    return def;
  },

  resetPosClicked() {
    this.close();

    setTimeout(() => {
      const view = new ConfirmPopupView();
      view.open(
        Locale.translate('reset_pos_to_factory_defaults'),
        Locale.translate(
          'this_action_will_fully_clean_this_pos_after_performing_it_the_pos_must_be_fully_shutdown_and_powered_on_again',

        ),
      ).then(() => {
        Toaster.success(Locale.translate('sending_command_{command}', {
          command: 'reset/factory',
        }));

        CashRegisterApi.call(`/reset/factory?account_name=${Upx.getAccountName()}`, 'post', {
          timeout: 30000,
        }).then(
          () => {
            Toaster.success(Locale.translate('pos_reset_started'));
          },
          () => {
            Toaster.error(Locale.translate('fail_to_factory_reset_the_pos'));
          },
        );
      });
    });
  },
}));
