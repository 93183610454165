define([
  'jquery',
  'underscore',
  'backbone',

  './printerSetting',

  'modules/common/components/locale',
], (
  $, _, Backbone,
  PrinterSettingsView,
  Locale,
) => Backbone.Marionette.CollectionView.extend({

  className: 'printer-settings',

  childView: PrinterSettingsView,

  childViewOptions(printerModel) {
    return {
      printerModel,
      model: this.model,
      label: `${Locale.translate('all_in_one_printer')} (${printerModel.get('label')})`,
    };
  },

}));
