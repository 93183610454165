define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cellWithKeyboard',
  'modules/shop.cash-register-retail/templates/products/list/cellPrice.hbs',
  'modules/common/components/currency',
], (
  $, _, Backbone, CellView, Template, Currency,
) => CellView.extend({

  template: Template,

  className() {
    return `${this.model.get('id')} text-right price-width`;
  },

  initialize(options) {
    options = options || {};

    this.productModel = options.productModel;
    this.keyboardMode = this.keyboardModel.MODE_PRODUCT_PRICE;

    this.setPreviousPrice();

    CellView.prototype.initialize.call(this, options);
  },

  onShow() {
    this.productModel.on('change:price_wt', this.render, this);
    this.productModel.on('change:discount_ppu_wt', this.setPreviousPrice, this);
    this.productModel.on('change:discount_percentage', this.setPreviousPrice, this);
    this.productModel.on('change:quantity', this.setPreviousPrice, this);
    this.productModel.on('change:ppu_wt', this.setPreviousPrice, this);
  },

  onDestroy() {
    this.productModel.off('change:price_wt', this.render, this);
    this.productModel.off('change:discount_ppu_wt', this.setPreviousPrice, this);
    this.productModel.off('change:discount_percentage', this.setPreviousPrice, this);
    this.productModel.off('change:quantity', this.setPreviousPrice, this);
    this.productModel.off('change:ppu_wt', this.setPreviousPrice, this);
  },

  setPreviousPrice() {
    this.productModel.calculateTotalPrices();
    this.previousPrice = this.productModel.get('price_wt');
  },

  onRender() {
  },
  onModeSelected() {
    CellView.prototype.onModeSelected.call(this);
  },
  onModeDeselected() {
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      let currentPrice = this.productModel.get('price_wt');
      if (this.firstInput) {
        currentPrice = '0.00';
        this.firstInput = false;
      }
      let newPrice = this.handleInputOnPrice(currentPrice, key);
      if (parseFloat(newPrice) > 100000.00) {
        newPrice = '100000.00';
      }

      this.productModel.set({
        price_wt: newPrice,
      });
    }
  },

  onBackspacePress() {
    // Mark price as changed
    this.firstInput = false;
    // Update the price
    const newPrice = this.removeDigitFromPrice(this.productModel.get('price_wt'));
    this.productModel.set('price_wt', newPrice);
  },

  onConfirmation() {
    CellView.prototype.onConfirmation.call(this);
    if (this.getDiscountPercentage() !== 100) {
      this.confirmPrice();
    } else {
      // 100% always results in 0 do not calculate
      this.onCancel();
    }
  },

  descriptionChanged() {
    const currentPrice = this.productModel.get('price_wt');
    const { previousPrice } = this;
    if (currentPrice !== '0.00') { // prevent changing to 0 price, discount 100% should be used instead
      return false;
    }
    return currentPrice !== previousPrice;
  },

  getDiscountPercentage() {
    return parseFloat(this.productModel.get('discount_percentage') || 0);
  },

  confirmPrice() {
    this.previousPrice = this.productModel.get('price_wt');
    // calculate in floats to get best apriximations of ppu,
    // after setting the ppuWt it will recalcuate the price back and fix the difference
    const price = parseFloat(this.productModel.get('price_wt'));
    const discountPercentage = this.getDiscountPercentage();

    // ow not 100% discount lets calculate it
    const qty = parseInt(this.productModel.get('quantity'));
    let unitPrice = price / ((1 - (discountPercentage / 100)) * qty);
    unitPrice = this.roundHalfDown(unitPrice);

    this.productModel.setPpuWt(unitPrice.toFixed(2));
    this.productModel.clearLengthPriceIfSet();
  },

  roundHalfDown(number, prec) {
    if (typeof prec !== 'number') {
      prec = 2;
    }
    const power = Math.pow(10, prec);
    const sign = Math.sign(number);
    return Math.floor(Math.abs(number) * power) / power * sign;
  },

  resetPrice() {
    if ((typeof this.previousPrice) === 'number') {
      console.warn('previous price was float');
      this.previousPrice = Currency.toCurrency(this.previousPrice);
    }
    this.productModel.set({
      price_wt: this.previousPrice,
    });
  },

  onCancel() {
    this.resetPrice();
  },

  afterCellClicked() {
    this.triggerMethod('product:total', {
      productModel: this.productModel,
    });
  },

  serializeData() {
    const data = this.productModel.toJSON();
    data.price_wt_formatted = this.productModel.formatPrice(data.price_wt || '0.00');
    return data;
  },

}));
