define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/totals',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/views/products/total/discount',
  'modules/common/components/locale',
], (
  $, _, Backbone, Template,
  OrderItemCollection, Currency, DiscountView, Locale,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'product-totals',

  ui: {
    discount: '[data-ui="discount"]',
    subtotal: '[data-ui="subtotal"]',
    total: '[data-ui="total"]',
  },
  //
  events: {
    'click [data-action="sub"]': 'subClicked',
  },

  regions: {
    discount: '@ui.discount',
  },

  childEvents: {
    'discount:clicked': 'discountClicked',
  },

  discountClicked() {
    this.triggerMethod('discount:clicked');
  },

  subClicked() {
    // that is for the back button in the payments screen
    this.triggerMethod('subtotal:clicked');
  },

  initialize(options) {
    this.orderItemCollection = options.orderItemCollection || OrderItemCollection;
  },

  onShow() {
    this.orderItemCollection.on('all', this.renderTotals, this);

    this.getRegion('discount').show(new DiscountView({
      isEditable: this.options.isEditable,
      orderItemCollection: this.orderItemCollection,
    }));
  },

  onDestroy() {
    this.orderItemCollection.off('all', this.renderTotals, this);
  },
  render() {
    if (!this.isFocused) {
      // prevent full rendering when focused
      Backbone.Marionette.LayoutView.prototype.render.call(this);
    } else {
      // only percentage
      this.renderPercentage();
    }
  },

  onRender() {
    this.renderTotals();
  },

  renderTotals() {
    const total = this.orderItemCollection.getTotalPriceWt();
    const qty = this.orderItemCollection.getTotalItems();
    const discount = this.orderItemCollection.getTotalDiscountWt();
    const subtotal = Currency.Math.add(total, discount);

    let $el = this.ui.total.find('b');
    if ($el) {
      $el.text(Currency.format('EUR', total));
    }
    $el = this.ui.total.find('span');
    if ($el) {
      $el.text(Locale.translate('total_{0}', qty.toString()));
    }
    $el = this.ui.subtotal.find('b');
    if ($el) {
      $el.text(Currency.format('EUR', subtotal));
    }
  },

  renderParts: _.debounce(function () {
    this.render();
  }, 5),

  serializeData() {
    return {
      show_back_button: this.options.show_back_button,
    };
  },

}));
